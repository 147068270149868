import { Project } from "./project";
import JSZip from "jszip";
import FileSaver from "file-saver";
import axios, {  AxiosResponse } from "axios";


export class Exporter {
    static async getFileContent(s: string): Promise<string> {
        let data = "";
        await axios({
            url: s, 
            method: 'GET',
            responseType: 'blob', // important
            }).then((response: AxiosResponse) => {
                data = response.data.text();
        });

        return data;
    }

    static async downloadProject(project: Project): Promise<void> {
        
        const zip = new JSZip();

        zip.file("package.json", await this.getFileContent("/clean/package.json"));
        zip.file(".eslintrc.js", await this.getFileContent("/clean/eslintrc.js"));
        zip.file("package.json", await this.getFileContent("/clean/package.json"));
        zip.file("babel.config.js", await this.getFileContent("/clean/babel.config.js"));
        zip.file(`${project.name}.code-workspace`, await this.getFileContent("/clean/project.code-workspace"));


        const pub = zip.folder("public");
        if (!pub) return;
        pub.file("index.html", await this.getFileContent("/clean/public/index.html"));
        pub.file("index.html", await this.getFileContent("/clean/public/index.html"));

        const src = zip.folder("src");
        if (!src) return;

        src.file("main.js", await this.getFileContent("/clean/src/main.js"));
        src.file("registerServiceWorker.js", await this.getFileContent("/clean/src/registerServiceWorker.js"));
        
        const assets = src.folder("assets");
        if (!assets) return;

        assets.file("logo.png", await this.getFileContent("/clean/src/assets/logo.png"));

        const plugins = src.folder("plugins");
        if (!plugins) return;

        const v = await this.getFileContent("/clean/src/plugins/vuetify.js");
        plugins.file("vuetify.js", v.replace('{{primary}}', `'${project.color[0]}'`));


        const store = src.folder("store");
        if (!store)
            return;

        store.file("index.js", await this.getFileContent("/clean/src/store/index.js"));

        //var plugins = src.folder("plugins");
        const views = src.folder("views");

        if (!views) return;
        
        // add views
        let routes = "";
        let links = "";
        
        project.views.forEach((page) => {
            views.file(`${page.name}.vue`, page.serialize());
            routes += `{ path: '${page.route}', name: '${page.name}', component: () => import('../views/${page.name}.vue') },\n`;
            links += `<v-btn text to="${page.route}">${page.name}</v-btn>\n`;
        });

        const router = src.folder("router");

        if (!router)
            return;

        await this.getFileContent("/clean/src/router/index.js").then((text: string) => 
        {
            router.file("index.js", text.replace('{{routes}}', routes))
        }
        );

        console.log('asdf');


        let s = "";
        await this.getFileContent("/clean/src/App.vue").then((text: string) => 
        {
            s = text.replace('{{links}}', links).replace('{{title}}', project.name);
            src.file("App.vue", s)
        }
        );

        console.log(s);


        //const components = src.folder("components");

        // add components
        //project.views.forEach((page) =>
        //    components.file(`${page.name}.vue`, `<template><h1>${page.name}</h1></template>`)
        //);

        zip.generateAsync({type:"blob"}).then(function(content) {
            FileSaver.saveAs(content, `${project.name}.zip`);
        });

        return;
    }
}
