import  "reflect-metadata"
import { Type } from "class-transformer";
import { Control } from "./project";

export abstract class Widget {
    name: string;
    component: string;
    icon: string;
    

    @Type(() => WidgetProperty)
    properties: WidgetProperty[];
    
    handlers: string[];

    constructor(name: string, component: string, icon: string) {
        this.name = name;
        this.icon = icon;
        this.component = component;
        this.properties =[];
        this.handlers = [];
    }

    addProperty(name: string, deflt: unknown, type: WidgetType, min = 0, max = 100, list: string[] = []): void {
        this.properties.push(new WidgetProperty(name, deflt, type, min, max, list));
    }

    addHandler(handler: string): void {
        this.handlers.push(handler);
    }

    abstract serialize(control: Control): WidgetSerial;
}

export interface WidgetSerial {
    data: string;
    template: string;
    methods: string;
}

export enum WidgetType {
    Text,
    Slider,
    List,
    Switch,
    TextArea,
    Identifier,
}

export class WidgetProperty {
    name: string;
    default: unknown;


    type: WidgetType;
    min: number;
    max: number;
    list: string[];

    constructor(name: string, deflt: unknown, type: WidgetType, min = 0, max = 0, list: string[] = []) {
        this.name = name;
        this.default = deflt;
        this.type = type;
        this.min = min;
        this.max = max
        this.list = list
    }
}