import { Exporter } from "./exporter";
import { Widget, WidgetType, WidgetProperty } from "./widget";
import axios, {  AxiosResponse } from "axios";
import { Guid } from "guid-typescript";
import  "reflect-metadata"
import { Type, plainToClassFromExist } from "class-transformer";

export class Project {
    id: string;
    name: string;
    github: string;

    color: Array<string>;

    @Type(() => View)
    views: View[];

    constructor(name?: string, github?: string) {
        this.id = Guid.raw();
        this.views = [];
        this.name = name || '';
        this.github = github || '';

        this.color = ['#274060', '#3993dd', '#e5d352', '#ac3931', '#2196F3', '#4CAF50', '#FFC107'];
    }

    addView(): View {
        const view = new View(this.views.length+1);
        this.views.push(view);
        return view;
    }

    addComponent(): void {
        return;
    }

    async download():Promise<void> {
        await Exporter.downloadProject(this);
    }

    static async fromGit(url: string): Promise<Project> {
        let proj: Project = new Project();
        await axios({
            url: url, 
            method: 'GET',
            responseType: 'application/json', // important
            }).then((response: AxiosResponse) => {
                console.log(response.data);
                proj = plainToClassFromExist(proj, response.data);
            });

        return proj;
    }

    static async saveToGit(url: string, project: Project): Promise<boolean> {
        console.log(url);
        await axios({
            url: url, 
            method: 'PUT',
            data: {
                comment: 'This from vv',
                content: JSON.stringify(project)
            },
            responseType: 'application/json', // important
            }).then((response: AxiosResponse) => {
                console.log(response.data);
            });

            return true;
    }

    static async loadProjects(repo: string, user: string): Promise<string[]> {
        let projList =  [] as string[];

        await axios({
            url: `https://api.github.com/repos/${user}/${repo}/contents/`, 
            method: 'GET',
            responseType: 'application/json', // important
            }).then((response: AxiosResponse) => {
                projList = response.data.map((p: { download_url: string} ) => p.download_url);
            });

        return projList;
    }
}

export class View {
    id: string;
    name: string;
    icon: string;
    class: string;
    route: string;
    styles: string;
    data: string;

    @Type(() => Handler)
    handlers: Handler[];

    // template controls
    @Type(() => Control)
    controls: Control[];

    constructor(id: number) {
        this.id = <string><unknown>id;
        this.name = "Page" + id;
        this.icon = "mdi-home";
        this.route = "/" + "page" + id;
        this.styles = "<style>\n</style>";
        this.class = "";
        this.data = "// Any additional page-level data here";

        this.handlers = [];
        this.controls = [];

        
        this.handlers.push(new Handler("mounted", ""));
        this.handlers.push(new Handler("unmounted", ""));
        this.handlers.push(new Handler("created", ""));
        this.handlers.push(new Handler("activated", ""));
        this.handlers.push(new Handler("deactivated", ""));
        this.handlers.push(new Handler("updated", ""));
        this.handlers.push(new Handler("beforeCreate", ""));
        this.handlers.push(new Handler("errorCapture", ""));
        this.handlers.push(new Handler("beforeUpdate", ""));
        this.handlers.push(new Handler("beforeMount", ""));
    }

    addControl(widget: Widget): void {
        const control = new Control(widget, this.controls.length+1);
        
        this.controls.push(control);
    }

    removeControl(control: Control): void {
        const index = this.controls.indexOf(control);
        if (index >= 0)
            this.controls.splice(index, 1);
    }

    serialize(): string {
        let templates = "<v-row>";
        let methods = "";
        let watch = "";
        let handlers = "";
        let data = `\n\t\t\t${this.data.replaceAll('\n', '\n\t\t\t')}\n`;

        this.controls.forEach(c => {
            const s = `<v-col cols="${c.propertyValue('cols')}">${c.widget.serialize(c).template}</v-col>`;
            templates += s;
            data += c.serializeData();
            methods += c.serializeHandlers();
            watch += `\n\t\t${c.propertyValue('model')}(newVal, oldVal) {\n\t\t${c.watch.replaceAll('\n', '\n\t\t\t')} \n\t\t}, `;
        });

        this.handlers.forEach(h => {
            handlers += `\n\t${h.name}() {\n\t\t${h.code.replaceAll('\n', '\n\t\t')}\n\t},\n`;
        });

        templates += "</v-row>";

        const script = `module.exports = {\n\tname: "${this.name}",\n\tdata() {\n\t\treturn {\n${data}\n\t\t}\n\t},\n\n\twatch: { ${ watch }\n\t},\n\n\t${handlers} \n\n\tmethods: {\n${methods}\n\t},\n}`;

        return `<template>\n${templates}</template>\n\n<script>\n${script}\n</script>\n\n${this.styles}`;
    }
}

export class Control {
    selected: boolean;
    watch: string;

    @Type(() => Widget)
    widget: Widget;
    
    @Type(() => Property)
    properties: Property[];

    @Type(() => Handler)
    handlers: Handler[];


    constructor(widget: Widget, number: number) {


        this.widget = widget;
        this.selected = false;

        this.watch = "// watch code here. this code will be called when the model for this value changes";

        this.properties = [];

        this.handlers = [];

        const model = widget?.name + number;

        this.properties.push(new Property(new WidgetProperty("model", model, WidgetType.Identifier), model));
        this.properties.push(new Property(new WidgetProperty("cols", "4", WidgetType.Slider, 1, 12), 4));

        widget?.handlers.forEach(h => this.handlers.push(new Handler(h, "// code here")));
        widget?.properties.forEach(p => this.properties.push(new Property(p, p.default)));
    }

    setPropertyValue(propName: string, value: string): unknown {
        const prop = this.properties.find(c=> c.widgetProperty.name == propName);
        if (prop)
            prop.value = value;

        return;
    }

    propertyValue(name: string): unknown {
        return this.properties.find(c=> c.widgetProperty.name == name)?.value;
    }

    serializeData(): string {
        return `\t\t\t${this.propertyValue('model')}: null,\n`;
    }

    serializeHandlerCallers(): string {
        let hndlrs = "";
        this.handlers.forEach((h: Handler) => {
            hndlrs += `@${h.name}="${this.propertyValue('model')}_${h.name}" `;
        });

        return hndlrs;
    }

    serializeHandlers(): string {
        let methods = "";
        this.handlers.forEach((h) => {
            methods += `\t${this.propertyValue('model')}_${h.name}() {\n\t\t${h.code.replaceAll('\n', '\n\t\t')}\n\t},\n\n`;
        });

        return methods;
    }
}

class Property {
    widgetProperty: WidgetProperty;
    type: string;
    value: unknown;

    constructor(property: WidgetProperty, value: unknown) {
        this.widgetProperty = property;
        this.value = value;
        this.type = 'value';
    }
}

class Handler {
    name: string;
    code: string;

    constructor(name: string, code: string) {
        this.name = name;
        this.code = code;
    }

}